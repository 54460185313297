import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInstance } from '../utils'

const initialState = {
    isAuthenticate : false,
    loading : false,
    error : null
}

export const authenticate = createAsyncThunk('authentication', async (credentials, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('accounts/authenticate/', credentials);
      return response.data;
    } catch (error) {
      const data = error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message;
      return rejectWithValue(data);
    }
  });

  const authenticationSlice = createSlice({
    name:'authenticate',
    initialState,
    reducers:{
      setAuthenticate : (state,action) => {
        state.isAuthenticate = action.payload
      }
    },
    extraReducers(builder){
        builder
        .addCase(authenticate.pending,(state) => {
            state.error = null
            state.loading = true
        })
        .addCase(authenticate.fulfilled,(state) => {
            state.isAuthenticate = true
            state.loading = false
        })
        .addCase(authenticate.rejected,(state,action) => {
            state.error = action.payload
            state.loading = false
        })
    }
  })

  export const { setAuthenticate } = authenticationSlice.actions;

  export const getIsAuthenticate = (state) => state.authenticate.isAuthenticate;
  export const getLoading = (state) => state.authenticate.loading;
  export const getError = (state) => state.authenticate.error;

  export default authenticationSlice.reducer;