import { Alert, AlertTitle, Box } from '@mui/material'
import React from 'react'

const Error = ({severity, errorMsg}) => {
    return (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Alert severity={severity}>
                <AlertTitle>{errorMsg}</AlertTitle>
            </Alert>
        </Box>
    )
}

export default Error