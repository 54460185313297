import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInstance } from '../utils'

const initialState = {
    payment : {},
    isCreated : false,
    isLoading : false,
    error : null
}

export const createPayment = createAsyncThunk('payment/create',async (credentials, {rejectWithValue}) =>{
    try{
        const response = await axiosInstance.post(`customer/payment/create/`,credentials)
        return response.data;
    }catch (error) {
        const data  = error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message
            return rejectWithValue(data)
    }
})

export const detailPayment = createAsyncThunk('payment/detail',async (id, {rejectWithValue}) =>{
    try{
        const response = await axiosInstance.get(`customer/payment/detail/${id}/`)
        return response.data;
    }catch (error) {
        const data  = error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message
            return rejectWithValue(data)
    }
})

const paymentSlice = createSlice({
    name:'payment',
    initialState,
    reducers:{
        resetPayment : (state) => {
            state.isCreated = false
            state.isLoading = false
            state.error = null
        }
    },
    extraReducers(builder){
        builder
        .addCase(createPayment.pending,(state) => {
            state.error = null
            state.isLoading = true
        })
        .addCase(createPayment.fulfilled,(state)=>{
            state.isCreated = true
            state.isLoading = false
        })
        .addCase(createPayment.rejected,(state,action) =>{
            state.error = action.payload
            state.isLoading = false
        })
        .addCase(detailPayment.pending,(state) => {
            state.error = null
            state.isLoading = true
        })
        .addCase(detailPayment.fulfilled,(state,action) =>{
            state.payment = action.payload
            state.isLoading = false
        })
        .addCase(detailPayment.rejected,(state,action) => {
            state.error = action.payload
            state.isLoading = false
        })
    }
})

export const { resetPayment } = paymentSlice.actions;

export const getPayment = (state) => state.payment.payment;
export const getIsCreated = (state) => state.payment.isCreated;
export const getIsLoading = (state) => state.payment.isLoading;
export const getError = (state) => state.payment.error;

export default paymentSlice.reducer;