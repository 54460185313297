import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInstance } from '../utils'

const initialState = {
    customerList : [],
    customerDetail : {},
    loading : false,
    error : null,
    isCreated : false,
    createLoading : false,
    createError : null
}

export const createCustomer = createAsyncThunk('customer/create',async (credentials, {rejectWithValue}) =>{
    try{
        const response = await axiosInstance.post('customer/create/',credentials)
        return response.data;
    }catch (error) {
        const data  = error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message
            return rejectWithValue(data)
    }
})

export const listCustomer = createAsyncThunk('customer/list',async (_, {rejectWithValue}) =>{
    try{
        const response = await axiosInstance.get('customer/')
        return response.data;
    }catch (error) {
        const data  = error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message
            return rejectWithValue(data)
    }
})

export const detailCustomer = createAsyncThunk('customer/detail',async (id, {rejectWithValue}) =>{
    try{
        const response = await axiosInstance.get(`customer/detail/${id}/`)
        return response.data;
    }catch (error) {
        const data  = error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message
            return rejectWithValue(data)
    }
})

const customerSlice = createSlice({
    name:'customer',
    initialState,
    reducers:{
        resetCustomer : (state) => {
            state.customerList = []
            state.customerDetail = {}
            state.loading = false
            state.error = null
            state.isCreated = false
            state.createLoading = false
            state.createError = null
        }
    },
    extraReducers(builder){
        builder
        .addCase(createCustomer.pending,(state) => {
            state.error = null
            state.createLoading = true
        })
        .addCase(createCustomer.fulfilled,(state,action)=>{
            state.customerDetail = action.payload
            state.isCreated = true
            state.createLoading = false
        })
        .addCase(createCustomer.rejected,(state,action)=>{
            state.createError = action.payload
            state.createLoading = false
        })
        .addCase(listCustomer.pending,(state)=> {
            state.error = null
            state.loading = true
        })
        .addCase(listCustomer.fulfilled,(state,action)=>{
            state.customerList = action.payload
            state.loading = false
        })
        .addCase(listCustomer.rejected,(state,action)=>{
            state.error = action.payload
            state.loading = false
        })
        .addCase(detailCustomer.pending,(state)=>{
            state.error = null
            state.loading = true
        })
        .addCase(detailCustomer.fulfilled,(state,action)=>{
            state.customerDetail = action.payload
            state.loading = false
        })
        .addCase(detailCustomer.rejected,(state,action)=>{
            state.error = action.payload
            state.loading = false
        })
    }
})

export const { resetCustomer } = customerSlice.actions;

export const getCustomerDetail = (state) => state.customer.customerDetail;
export const getCustomerList = (state) => state.customer.customerList;
export const getLoading = (state) => state.customer.loading;
export const getError = (state) => state.customer.error;
export const getIsCreated = (state) => state.customer.isCreated;
export const getCreateLoading = (state) => state.customer.createLoading;
export const getCreateError = (state) => state.customer.createError;

export default customerSlice.reducer;