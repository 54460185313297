import { Box } from '@mui/material'
import React from 'react'
import { orbit } from 'ldrs'


const PopLoading = () => {
    orbit.register()
    return (
        <l-orbit
        size="55"
        speed="1.5" 
        color="#f83245" 
        ></l-orbit>
    )
}

export default PopLoading;





// Default values shown






