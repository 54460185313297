import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Error, Loading, Popup, PopLoading } from '../components'
import { createCustomerService, listCustomerService, resetCustomerService, getCustomerService, getCreateDError, getCreatedLoading, getError, getIsCreated, getLoading } from '../reducers/customerServiceSlice'
import { Box, Button, Divider, Paper, TableHead, TableCell, Table, Typography, Grid, TextField, InputLabel, OutlinedInput, InputAdornment, MenuItem, FormControl, TableBody } from '@mui/material'
import { styled } from '@mui/material/styles'
import { CurrencyRupee } from '@mui/icons-material'
import CustomerServiceList from './CustomerServiceList'
import { setActive } from '../reducers/succesAnimationSlice'

const StyleTableHead = styled(TableHead)(({theme})=>({
    fontWeight: '600',
    // color: theme.palette.primary,
    backgroundColor: '#adb5bd',
}))

const StyleTableCell = styled(TableCell)(({theme})=>({
    // color: theme.palette.primary.main,
    textAlign:'center'
}))

const CustomerService = ({id}) => {
    const dispatch = useDispatch();
    const loading = useSelector(getLoading);
    const error = useSelector(getError);
    const createLoading = useSelector(getCreatedLoading);
    const createError = useSelector(getCreateDError);
    const isCreated = useSelector(getIsCreated);
    const customerService = useSelector(getCustomerService)
    const [openCustomer,setOpenCustomer] = useState(false);
    const [title,setTitle] = useState('');
    const [amount,setAmount] = useState('');
    const [paymentType,setPaymentType] = useState('');
    const handleAmount = (e) => {
        if(Number(e.target.value)){
            setAmount(Number(e.target.value))
        }else{
            setAmount('');
        }
    }
    const handleSubmit = () => {
        dispatch(createCustomerService({title,amount,paymentType,id}));
    }
    const canSubmit = Boolean(title) && Boolean(amount) && Boolean(paymentType) && !createLoading
    React.useEffect(() => {
        dispatch(listCustomerService(id));
        if(isCreated){
            dispatch(resetCustomerService());
            setOpenCustomer(false);
            dispatch(setActive(true));
        }
    },[dispatch,id,isCreated]);
    return (
        <>
        {loading ?
            <Loading />
        :error ?
            <Error severity={'error'} errorMsg={error} />
        : customerService &&
        <>
            <Paper sx={{ p: {xs: 2, md: 3}}}>
                <Box mb={2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                    <Button variant='contained' onClick={()=>setOpenCustomer(true)} >Add Service</Button>
                </Box>
                <Divider>
                    <h4 className='.boot_time'>Customer Service</h4>
                </Divider>
                {customerService.length === 0 ?
                    <Typography textAlign={'center'}>There is no data to display...</Typography>
                :
                    <Box >
                        <Table size='small'>
                            <StyleTableHead>
                                {/* <StyleTableCell>ID</StyleTableCell> */}
                                <StyleTableCell>Title</StyleTableCell>
                                <StyleTableCell>Amount</StyleTableCell>
                                <StyleTableCell>Payment Type</StyleTableCell>
                                <StyleTableCell>Date</StyleTableCell>
                                <StyleTableCell>Action</StyleTableCell>
                            </StyleTableHead>
                            <TableBody>
                                {customerService && customerService.map((item) =>{
                                    return(
                                        <CustomerServiceList customerService={item} />
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Box>
                }
            </Paper>
            <Popup
                title={'Add Customer Service'}
                openPopup={openCustomer}
                setOpenPopup={setOpenCustomer}
            >
                <Grid container spacing={3}>
                    <Grid item md={12} sm={12} sx={12} xl={12}>
                        <TextField type='text' label='Service Name' fullWidth value={title} onChange={(e)=>setTitle(e.target.value)} size='small' />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <FormControl fullWidth size='small'>
                            <InputLabel htmlFor='amount'>Amount</InputLabel>
                            <OutlinedInput 
                                id='amount'
                                startAdornment={<InputAdornment position='start'><CurrencyRupee/></InputAdornment>}
                                label='Amount'
                                required
                                value={amount}
                                onChange={handleAmount}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xl={6} xs={12}>
                        <TextField  label='Payment Type' value={paymentType} onChange={(e)=>setPaymentType(e.target.value)} size='small' select fullWidth >
                            <MenuItem value='Monthly'>Monthly</MenuItem>
                            <MenuItem value='Yearly'>Yearly</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
                {createError &&
                    <Error severity={'error'} errorMsg={createError} />
                }
                <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} mt={2}>
                    <Button variant='contained' onClick={handleSubmit} disabled={!canSubmit} >{createLoading ? <PopLoading/>:'Submit'}</Button>
                </Box>
            </Popup>
        </> 
        }
        </>
    )
}

export default CustomerService