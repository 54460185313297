import { Alert, AlertTitle, Box } from '@mui/material'
import React from 'react'

const Error = ({message}) => {
    return (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{paddingTop:'50px'}}>
            <Alert severity={'warning'}>
                <AlertTitle>{message}</AlertTitle>
            </Alert>
        </Box>
    )
}

export default Error