import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Chip, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, TableCell, TableRow, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import { AddCard, CurrencyRupee, Print, Visibility } from '@mui/icons-material'
import { Popup, PopLoading, Error, Loading } from '../components'
import { createPayment, resetPayment, getIsCreated, getIsLoading, getError, detailPayment, getPayment } from '../reducers/paymentSlice'
import { setActive } from '../reducers/succesAnimationSlice'

const StyleTableCell = styled(TableCell)(({theme})=>({
    // color: theme.palette.primary.main,
    textAlign:'center'
}))

const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
};

const cellStyle = {
    border: '1px solid black',
    padding: '5px',
    textAlign:'center',
    textTransform: 'capitalize'
};

const thStyle = {
    ...cellStyle, // Spread the cellStyle and add more styles
    textAlign: 'center',
};

const InvoiceList = ({item}) => {
    const dispatch = useDispatch()
    const isCreated = useSelector(getIsCreated);
    const isLoading = useSelector(getIsLoading);
    const error = useSelector(getError);
    const payment = useSelector(getPayment);
    const [openPayment,setOpenPayment] = useState(false);
    const [amount,setAmount] = useState('');
    const [paymentMode,setPaymentMode] = useState('');
    const [remark,SetRemark] = useState('')
    const [openInvoice,setOpenInvoice] = useState(false)
    const canSubmit = Boolean(amount) && Boolean(paymentMode) && Boolean(remark) && !isLoading;
    const submitHandle = () =>{
        dispatch(createPayment({
            id : item.id,
            customer : item.customer,
            amount,
            paymentMode,
            remark
        }))
    }
    const paymentDetailHandle = () => {
        dispatch(detailPayment(item.id))
        setOpenInvoice(true)
    }
    useEffect(() => {
        setAmount(item.amount);
        if(isCreated){
            dispatch(resetPayment());
            setOpenPayment(false);
            dispatch(setActive(true));
        }
    },[item,isCreated]);
    return (
        <TableRow key={item.id} >
            <StyleTableCell>{item.title}</StyleTableCell>
            <StyleTableCell>{item.amount}</StyleTableCell>
            <StyleTableCell>{item.timestamp && item.timestamp.substring(0,10)}</StyleTableCell>
            <StyleTableCell>{item.user}</StyleTableCell>
            <StyleTableCell>{item.isPaid ? <Chip label='Paid' color='success' size='small' />:<Chip label='Not Paid' color='error' size='small' />}</StyleTableCell>
            <StyleTableCell>
                    {item.isPaid ?
                        <Box>
                            <IconButton><Print/></IconButton>
                            <IconButton onClick={paymentDetailHandle}><Visibility/></IconButton>
                        </Box>
                    :
                        <IconButton onClick={()=>setOpenPayment(true)}><AddCard/></IconButton>
                    }
            </StyleTableCell>
            <Popup
                title={'Add Payment'}
                openPopup={openPayment}
                setOpenPopup={setOpenPayment}
            >
                <Grid container spacing={2}>
                    <Grid item md={6} sm={12}>
                        <FormControl fullWidth size='small'>
                            <InputLabel htmlFor='amount'>Amount</InputLabel>
                            <OutlinedInput 
                                id='amount'
                                startAdornment={<InputAdornment position='start'><CurrencyRupee/></InputAdornment>}
                                label='Amount'
                                value={amount}
                                onChange={(e)=>setAmount(e.target.value)}
                                disabled
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField label='Payment Mode' variant='outlined' size='small' fullWidth select value={paymentMode} onChange={(e)=>setPaymentMode(e.target.value)}>
                            <MenuItem value='cash'>Cash</MenuItem>
                            <MenuItem value='esewa'>E-Sewa</MenuItem>
                            <MenuItem value='bank transfer'>Bank Transfer</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item md={12} sm={12}>
                        <TextField label='Remark' variant='outlined' size='small' fullWidth value={remark} onChange={(e)=>SetRemark(e.target.value)} />
                    </Grid>
                </Grid>
                {error &&
                    <Error severity={'error'} errorMsg={error}  />
                }
                <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} mt={1}>
                    <Button  variant='contained' onClick={submitHandle} disabled={!canSubmit}>{isLoading ? <PopLoading/>:'Submit'}</Button>
                </Box>
            </Popup>
            <Popup
                title={'Invoice Detail'}
                openPopup={openInvoice}
                setOpenPopup={setOpenInvoice}
            >
                {isLoading ?
                    <Loading />
                :payment &&
                    <table style={tableStyle}>
                        <tr>
                            <th style={thStyle}>Title</th>
                            <td style={cellStyle} colSpan={3}>{payment.title}</td>
                        </tr>
                        <tr>
                            <th style={thStyle}>Invoice Number</th>
                            <td style={cellStyle}>{payment.invoiceId}</td>
                            <th style={thStyle}>Amount</th>
                            <td style={cellStyle}>{payment.amount}</td>
                        </tr>
                        <tr>
                            <th style={thStyle}>Payment Mode</th>
                            <td style={cellStyle}>{payment.paymentMode}</td>
                            <th style={thStyle}>User</th>
                            <td style={cellStyle}>{payment.createdBy}</td>
                        </tr>
                        <tr>
                            <th style={thStyle}>Date</th>
                            <td style={cellStyle}>{payment.timestamp && payment.timestamp.substring(0,10)}</td>
                            <th style={thStyle}>Remark</th>
                            <td style={cellStyle}>{payment.remark}</td>
                        </tr>
                    </table>
                }
            </Popup>
        </TableRow>
    )
}

export default InvoiceList