import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../utils'

const initialState = {
    customerServices : [],
    loading : false,
    error : null,
    isCreated : false,
    createdLoading: false,
    createdError : null,
}

export const createCustomerService = createAsyncThunk('customer/service/create',async (credentials, {rejectWithValue}) =>{
    try{
        const response = await axiosInstance.post(`customer/service/create/`,credentials)
        return response.data;
    }catch (error) {
        const data  = error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message
            return rejectWithValue(data)
    }
})

export const listCustomerService = createAsyncThunk('customer/service/list',async (id, {rejectWithValue}) =>{
    try{
        const response = await axiosInstance.get(`customer/service/list/${id}/`)
        return response.data;
    }catch (error) {
        const data  = error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message
            return rejectWithValue(data)
    }
})

const customerServiceSlice = createSlice({
    name:"customerService",
    initialState,
    reducers:{
        resetCustomerService : (state) => {
            state.createdError = null
            state.createdLoading = false
            state.error = null
            state.loading = false
            state.customerServices = []
            state.isCreated = false
        }
    },
    extraReducers(builder){
        builder
        .addCase(createCustomerService.pending,(state) => {
            state.createdError = null
            state.createdLoading = true
        })
        .addCase(createCustomerService.fulfilled,(state,action) => {
            state.isCreated = true
            state.createdLoading = false
        })
        .addCase(createCustomerService.rejected,(state,action) => {
            state.createdError = action.payload
            state.createdLoading = false
        })
        .addCase(listCustomerService.pending,(state) => {
            state.error = null
            state.loading = true
        })
        .addCase(listCustomerService.fulfilled,(state,action) => {
            state.customerServices = action.payload
            state.loading = false
        })
        .addCase(listCustomerService.rejected,(state,action) => {
            state.error = action.payload
            state.loading = false
        })
    }
})

export const { resetCustomerService } = customerServiceSlice.actions;

export const getCustomerService = (state) => state.customerService.customerServices;
export const getLoading = (state) => state.customerService.loading;
export const getError = (state) => state.customerService.error;
export const getIsCreated = (state) => state.customerService.isCreated;
export const getCreatedLoading = (state) => state.customerService.createdLoading;
export const getCreateDError = (state) => state.customerService.createdError;

export default customerServiceSlice.reducer;