import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { detailCustomer, getCustomerDetail, getLoading,getError } from '../reducers/customerSlice'
import { Loading, Error, CustomerPayment, CustomerService, Credential } from '../components'
import { Avatar, Box, Breadcrumbs, Chip, Divider, Grid, Link as MuiLink, Paper, Tab, Tabs, Typography } from '@mui/material'
import { CurrencyRupee, Dashboard, DesignServices, Home, PeopleAlt, Security } from '@mui/icons-material'
import { TabContext, TabPanel } from '@mui/lab'

const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
};

const cellStyle = {
    border: '1px solid black',
    padding: '5px',
    textAlign:'center',
    textTransform: 'capitalize'
};

const thStyle = {
    ...cellStyle, // Spread the cellStyle and add more styles
    textAlign: 'center',
};

const CustomerDetail = () => {
    const dispatch = useDispatch();
    const customer_detail = useSelector(getCustomerDetail);
    const loading = useSelector(getLoading);
    const error = useSelector(getError);
    const params = useParams();
    const { id } = params;
    const [tabValue,setTabValue] = useState(1)
    const tabHandler = (event, newValue) => {
        setTabValue(newValue)
    }
    useEffect(() =>{
        dispatch(detailCustomer(id));
    },[dispatch,id]);
    return (
        <React.Fragment>
            {loading ?
                <Loading />
            :error ?
                <Error severity={'error'} errorMsg={error} />
            :customer_detail &&
            <>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} mb={2}>
                    <Breadcrumbs>
                        <MuiLink
                            underline='hover'
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            component={Link}
                            to='/'
                        >
                            <Home sx={{ mr: 0.5 }} fontSize="inherit"/>Dashboard
                        </MuiLink>
                        <MuiLink
                            underline='hover'
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            component={Link}
                            to='/customer'
                        >
                            <PeopleAlt sx={{ mr: 0.5 }} fontSize="inherit"/>Customer List
                        </MuiLink>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary">{id}</Typography>
                    </Breadcrumbs>
                </Box>
                <TabContext value={tabValue}>
                    <Paper variant='outlined' sx={{p:{xs:2,md:3}}}>
                        <Grid container spacing={3} display='flex' justifyContent='space-between'>
                            <Grid item display={'flex'} justifyContent={'flex-start'}>
                                <Grid item mr={2}>
                                    <Avatar 
                                        src=''
                                        alt='customer_detail'
                                        sx={{width:100,height:100}}
                                        variant='rounded'
                                    />
                                </Grid>
                                <Grid item>
                                    <Box height={'100%'} mt={0.5} lineHeight={1}>
                                        <Typography variant='h5' fontWeight='bold' textTransform='capitalize'>{customer_detail.name}</Typography>
                                        <Typography variant='body1' color='text'>{customer_detail.contact_number}</Typography>
                                        <Typography variant='body1' color='text'>{customer_detail.email}</Typography>
                                        
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Tabs value={tabValue} onChange={tabHandler}>
                                    <Tab icon={<Dashboard/>} value={1} label='Detail' />
                                    <Tab icon={<DesignServices/>} value={2} label='Services' />
                                    <Tab icon={<CurrencyRupee/>} value={3} label='Payment'/>
                                    <Tab icon={<Security/>} value={4} label='Credential' />
                                </Tabs>
                            </Grid>
                        </Grid>
                    </Paper>
                    <TabPanel value={1}>
                        <Paper variant='outlined' sx={{ p: {xs: 2, md: 3}}}>
                            <Divider>
                                <h4 className='.boot_time'>Customer INFO</h4>
                            </Divider>
                            <Box mt={2}>
                                <table style={tableStyle}>
                                    <tr>
                                        <th style={thStyle}>Name</th>
                                        <td style={cellStyle} colSpan={3}>{customer_detail.name}</td>
                                    </tr>
                                    <tr>
                                        <th style={thStyle}>Address</th>
                                        <td style={cellStyle} colSpan={3}>{customer_detail.address}</td>
                                    </tr>
                                    <tr>
                                        <th style={thStyle}>Status</th>
                                        <td style={cellStyle}>{customer_detail.is_active ? <Chip label='Active' size='small' color='success' />:<Chip label='Inactive' size='small' color='error' /> }</td>
                                    </tr>
                                    <tr>
                                        <th style={thStyle}>Phone Number</th>
                                        <td style={cellStyle}>{customer_detail.contact_number}</td>
                                        <th style={thStyle}>E-Mail</th>
                                        <td style={cellStyle}>{customer_detail.email ? customer_detail.email : '-'}</td>
                                    </tr>
                                    <tr>
                                        <th style={thStyle}>Created By</th>
                                        <td style={cellStyle}>{customer_detail.createdBy}</td>
                                        <th style={thStyle}>Created Date</th>
                                        <td style={cellStyle}>{customer_detail.timestamp && customer_detail.timestamp.substring(0,10)}</td>
                                    </tr>
                                </table>
                            </Box>
                        </Paper>
                    </TabPanel>
                    <TabPanel value={2}>
                        <CustomerService id={id} />
                    </TabPanel>
                    <TabPanel value={3}>
                        <CustomerPayment id={id} />
                    </TabPanel>
                    <TabPanel value={4} >
                        <Credential id={id} />
                    </TabPanel>
                </TabContext>
            </>
            }
        </React.Fragment>
    )
}

export default CustomerDetail