import React from 'react'
import { Card, CardContent, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  color: 'white',
  minWidth: 200,
  borderRadius: 10,
  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.1)',
}));

const StyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

const StyledIconBox = styled(Box)(({ theme })=>({
  marginLeft: theme.spacing(2),
  marginRight: 2,
}));


const StatsCard = ({ title, value, icon, backgroundColor, backgroundColor1 }) => {
    const cardStyle = {
        background: backgroundColor ?  `linear-gradient(45deg, ${backgroundColor} 30%, ${backgroundColor1} 90%)`:'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'
    };
    return (
        <StyledCard style={cardStyle}>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
            <StyledCardContent>
                <Typography variant="h6" color='white'>{title}</Typography>
                <Typography variant="h4" color='white'>{value}</Typography>
            </StyledCardContent>
            <StyledIconBox mr={3}>{icon}</StyledIconBox>
            </Box>
        </StyledCard>
    )
}

export default StatsCard;