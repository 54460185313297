import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LockOutlined } from '@mui/icons-material';
import { Avatar, Box, CssBaseline, Container, Paper, Typography, TextField, Button, Divider, Grid } from '@mui/material';
import { getUserInfo } from '../reducers/authSlice'
import { authenticate, getIsAuthenticate, getLoading ,getError, setAuthenticate } from '../reducers/authenticationSlice'
import { PopLoading, Error, Loading, Popup } from '../components'
import {
    listCustomerCredential,
    createCustomerCredential,
    resetCustomerCredential,
    getCustomerCredential,
    getCreateLoading,
    getCreateError,
    getIsCreated,
    getLoading as getCredentialLoading,
    getError as getCredentialError,
} from '../reducers/customerCredentialSlice'
import { setActive } from '../reducers/succesAnimationSlice'

const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
};

const cellStyle = {
    border: '1px solid black',
    padding: '5px',
    textAlign:'center',
    textTransform: 'capitalize'
};

const thStyle = {
    ...cellStyle, // Spread the cellStyle and add more styles
    textAlign: 'center',
};

const Credential = ({id}) => {
    const dispatch = useDispatch();
    const userInfo = useSelector(getUserInfo);
    const isAuthenticate = useSelector(getIsAuthenticate);
    const loading = useSelector(getLoading);
    const error = useSelector(getError);
    const customerCredential = useSelector(getCustomerCredential);
    const isCreated = useSelector(getIsCreated);
    const createdLoading = useSelector(getCreateLoading);
    const createdError = useSelector(getCreateError);
    const credentialLoading = useSelector(getCredentialLoading);
    const credentialError = useSelector(getCredentialError);
    const [username,setUsername] = useState();
    const [password,setPassword] = useState();
    const [openCredential,setOpenCredential] = useState(false);
    const [uUsername,setuUsername] = useState('');
    const [uPassword,setuPassword] = useState('');
    const [dbUsername,setDbUsername] = useState('');
    const [dbPassword,setDbPassword] = useState('');
    const handleSubmit = (e) =>{
        e.preventDefault();
        dispatch(authenticate({username,password}));
    }
    // const canSubmit = password.length > 4 && !loading;
    const handleCredential = () => {
        dispatch(createCustomerCredential({
            id,
            username:uUsername,
            password:uPassword,
            dbUsername,
            dbPassword
        }));
    }
    useEffect(() => {
        setUsername(userInfo.username);
        setPassword('');
        if(isAuthenticate){
            dispatch(listCustomerCredential(id));
        }
        if(isCreated){
            dispatch(resetCustomerCredential());
            setOpenCredential(false);
            dispatch(setActive(true));
        }
    },[dispatch,id,isAuthenticate,isCreated]);
    
    return (
        <>
        {isAuthenticate ? 
            credentialLoading ?
                <Loading />
            :credentialError ?
                <Error severity={'error'} errorMsg={createdError} />
            :customerCredential &&
                <Paper variant='outlined' sx={{p:{xs:2,md:3}}}>
                    <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                        <Button variant='contained' onClick={()=>setOpenCredential(true)} >Add Credential</Button>
                    </Box>
                    <Divider>
                        <h4 className='.boot_time'>Customer Credential</h4>
                    </Divider>
                    {customerCredential.map((item) => {
                        return(
                            <Box mt={2}>
                                <table style={tableStyle}>
                                    <tr>
                                        <th style={thStyle} >Username</th>
                                        <td style={cellStyle}>{item.username}</td>
                                        <th style={thStyle} >Password</th>
                                        <td style={cellStyle}>{item.password}</td>
                                    </tr>
                                    <tr>
                                        <th style={thStyle} >DB Username</th>
                                        <td style={cellStyle}>{item.db_username}</td>
                                        <th style={thStyle} >DB Password</th>
                                        <td style={cellStyle}>{item.db_password}</td>
                                    </tr>
                                </table>
                            </Box>
                        )
                    })}
                    <Popup
                        title={'Add Credential'}
                        openPopup={openCredential}
                        setOpenPopup={setOpenCredential}
                    >
                        <Grid container spacing={2}>
                            <Grid item md={6} sm={12}>
                                <TextField type='text' label='Username' size='small' fullWidth value={uUsername} onChange={(e)=>setuUsername(e.target.value)} />
                            </Grid>
                            <Grid item md={6} sm={12}>
                                <TextField type='text' label='Password' size='small' fullWidth value={uPassword} onChange={(e) => setuPassword(e.target.value)} />
                            </Grid>
                            <Grid item md={6} sm={12}>
                                <TextField type='text' label='DB Username' size='small' fullWidth value={dbUsername} onChange={(e)=>setDbUsername(e.target.value)} />
                            </Grid>
                            <Grid item md={6} sm={12}>
                                <TextField type='text' label='DB Password' size='small' fullWidth value={dbPassword} onChange={(e)=>setDbPassword(e.target.value)} />
                            </Grid>
                        </Grid>
                        {createdError &&
                            <Error severity={'error'} errorMsg={createdError} />
                        }
                        <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} mt={2}>
                            <Button variant='contained' disabled={createdLoading} onClick={handleCredential} >{createdLoading ? <PopLoading/>:'Submit'}</Button>
                        </Box>
                    </Popup>
                </Paper>
        :<Container component="main" maxWidth="xs">
            <CssBaseline />
            <Paper>
            <Box
                sx={{
                    
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center'
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlined/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Please enter your password
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin='normal'
                        required
                        fullWidth
                        label='Password'
                        id='password'
                        size='small'
                        value={password}
                        type='password'
                        onChange={(e)=>setPassword(e.target.value)}
                        autoFocus
                    />
                    {error &&
                        <Error severity={'error'} errorMsg={error} />
                    }
                    <Button type='submit'fullWidth variant='contained' sx={{ mt: 3, mb: 2 }} disabled={loading} >{loading ? <PopLoading/>:'Submit'}</Button>
                </Box> 
            </Box>
            </Paper>
        </Container>
        }
        </>
    )
}

export default Credential