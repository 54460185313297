import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    active : false,
}

const successAnimationSlice = createSlice({
    name:"succesAnimation",
    initialState,
    reducers:{
        setActive : (state,action) =>{
            state.active = action.payload
        }
    }
})

export const { setActive } = successAnimationSlice.actions;

export const getSucessActive = (state) => state.succesAnimation.active;

export default successAnimationSlice.reducer;