import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { createCustomer, listCustomer, resetCustomer, getCustomerList, getCustomerDetail, getLoading, getError, getIsCreated, getCreateError, getCreateLoading } from '../reducers/customerSlice'
import { Error, Loading, Popup, usePagination, Warning, PopLoading } from '../components'
import { Box, Breadcrumbs, Button, Divider, Link as MuiLink, Paper, Table, TableContainer, TextField, Typography, TableHead, TableCell, TableBody, TableRow, IconButton, Grid, Pagination, MenuItem } from '@mui/material'
import { Add, Home, Visibility } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import { setActive } from '../reducers/succesAnimationSlice'

const StyleTableHead = styled(TableHead)(({theme})=>({
    fontWeight: '600',
    // color: theme.palette.primary,
    backgroundColor: '#adb5bd',
}))

const StyleTableCell = styled(TableCell)(({theme})=>({
    // color: theme.palette.primary.main,
    textAlign:'center'
}))

const Customer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const customer_list = useSelector(getCustomerList);
    const customer_detail = useSelector(getCustomerDetail);
    const isCreated = useSelector(getIsCreated);
    const loading = useSelector(getLoading);
    const error = useSelector(getError);
    const createLoading = useSelector(getCreateLoading);
    const createError = useSelector(getCreateError);
    const [openCustomer,setOpenCustomer] = useState(false);
    const [keyword,setKeyword] = useState('');
    const [name,setName] = useState('');
    const [contactNumber,setContactNumber] = useState('');
    const [email,setEmail] = useState('');
    const [address,setAddress] = useState('');
    const PER_PAGE = 10;
    const [page,setPage] = useState(1);
    const filterCustomers = customer_list.filter((item) => {
        return(
            item.name.toLowerCase().includes(keyword) || item.contact_number.toString().includes(keyword)
        )
    })
    const count = Math.ceil(filterCustomers.length / PER_PAGE);
    const customers = usePagination(filterCustomers,PER_PAGE)
    const handleChange = (e, p) => {
        setPage(p);
        customers.jump(p);
    };
    const handleSubmit = () => {
        dispatch(createCustomer({
            name,
            contactNumber,
            email,
            address,
        }))
    }
    // const handleAmount = (e) => {
    //     if(Number(e.target.value)){
    //         setAmount(Number(e.target.value))
    //     }else{
    //         setAmount('');
    //     }
    // }
    const canSubmit = Boolean(name) && Boolean(contactNumber) && Boolean(address) && !createLoading;
    useEffect(() => {
        dispatch(listCustomer());
        if(isCreated){
            dispatch(setActive(true));
            navigate(`/customer/${customer_detail.id}`)
            dispatch(resetCustomer());
        }
    },[dispatch,isCreated,navigate]);
    return (
        <React.Fragment>
            {loading ?
                <Loading />
            :error ?
                <Error severity={'error'} errorMsg={error} />
            :customer_list &&
                <>
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} mb={2}>
                        <Breadcrumbs>
                            <MuiLink
                                underline='hover'
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                component={Link}
                                to='/'
                            ><Home sx={{ mr: 0.5 }} fontSize="inherit"/>Dashboard</MuiLink>
                            <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary">Customer List</Typography>
                        </Breadcrumbs>
                    </Box>
                    <TableContainer component={Paper} sx={{p:{xs:2,md:3}}}>
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
                            <Button variant='contained' startIcon={<Add/>} onClick={() => setOpenCustomer(true)}>Customer</Button>
                            <TextField type='text' variant='standard' size='small' label='Search' value={keyword} onChange={(e)=>setKeyword(e.target.value)} />
                        </Box>
                        <Divider>
                            <h4 className='.boot_time'>Customer List</h4>
                        </Divider>
                        {customer_list.length === 0 ?
                            <Warning message={'No customer found!!!'} />
                        :
                        <>
                            <Table size='small'>
                                <StyleTableHead>
                                    <StyleTableCell>ID</StyleTableCell>
                                    <StyleTableCell>Name</StyleTableCell>
                                    <StyleTableCell>Phone Number</StyleTableCell>
                                    <StyleTableCell>Action</StyleTableCell>
                                </StyleTableHead>
                                <TableBody>
                                    {customers.currentData().map((item)=>{
                                        return(
                                            <TableRow key={item.id}>
                                                <StyleTableCell>{item.id}</StyleTableCell>
                                                <StyleTableCell>{item.name}</StyleTableCell>
                                                <StyleTableCell>{item.contact_number}</StyleTableCell>
                                                <StyleTableCell><Link to={`/customer/${item.id}`}><IconButton><Visibility/></IconButton></Link></StyleTableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                            <Box display={'flex'} justifyContent={'center'} alignContent={'center'} mt={2}>
                                <Pagination count={count} page={page} onChange={handleChange} />
                            </Box>
                        </>
                        }
                    </TableContainer>
                    <Popup
                        title='Add Customer'
                        openPopup={openCustomer}
                        setOpenPopup={setOpenCustomer}
                    >
                        <Grid container spacing={2}>
                            <Grid item md={12} xl={12} sm={12}>
                                <TextField value={name} onChange={(e)=>setName(e.target.value)} type='text' required label='Name' size='small' variant='outlined' fullWidth />
                            </Grid>
                            <Grid item md={6} xl={6} sm={12}>
                                <TextField value={contactNumber} onChange={(e)=>setContactNumber(e.target.value)} type='text' required label='Phone Number' size='small' variant='outlined' fullWidth />
                            </Grid>
                            <Grid item md={6} xl={6} sm={12}>
                                <TextField value={email} onChange={(e)=>setEmail(e.target.value)} type='email' label='E-Mail' size='small' variant='outlined' fullWidth />
                            </Grid>
                            <Grid item md={12} xl={12} sm={12}>
                                <TextField value={address} onChange={(e)=>setAddress(e.target.value)} type='text' required label='Address' size='small' variant='outlined' fullWidth />
                            </Grid>
                        </Grid>
                        {createError &&
                        <Box mt={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Error severity={'error'} errorMsg={createError} />
                        </Box>
                        }
                        <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
                            <Button variant='contained' onClick={handleSubmit} disabled={!canSubmit} >{createLoading ? <PopLoading/>:"Submit"}</Button>
                        </Box>
                    </Popup>
                </>
            }
        </React.Fragment>
    )
}

export default Customer