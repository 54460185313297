import { Delete, Edit } from '@mui/icons-material'
import { Box, IconButton, TableCell, TableRow } from '@mui/material'
import React from 'react'

const CustomerServiceList = ({customerService}) => {
    return (
        <TableRow key={customerService.id}>
            <TableCell align='center'>{customerService.title}</TableCell>
            <TableCell align='center'>{customerService.amount}</TableCell>
            <TableCell align='center'>{customerService.payment_type}</TableCell>
            <TableCell align='center'>{customerService && customerService.timestamp.substring(0,10)}</TableCell>
            <TableCell align='inherit'>
                <Box display={'flex'} justifyContent={'space-evenly'}>
                    <IconButton><Edit/></IconButton><IconButton color='error'><Delete/></IconButton>
                </Box>
            </TableCell>
        </TableRow>
    )
}

export default CustomerServiceList