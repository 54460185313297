import { createTheme } from '@mui/material/styles'

const theme = createTheme({
    palette: {
        primary: {
            main: "#333996",
            light: '#748ffc'
        },
        secondary: {
            main: "#f83245",
            light: '#f8324526'
        },
        background: {
            default: "#f4f5fd"
        },
    },
    overrides:{
        MuiAppBar:{
        root:{
            transform:'translateZ(0)'
        }
        }
    },
    props:{
        MuiIconButton:{
        disableRipple:true
        }
    }
})

export default theme;