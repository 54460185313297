import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInstance } from '../utils'

const initialState = {
    serverInfo: {},
    loading : false,
    error :null
}

export const infoServer = createAsyncThunk('server/info',async (_, {rejectWithValue}) =>{
    try{
        const response = await axiosInstance.get('dashboard/server/info')
        return response.data;
    }catch (error) {
        const data  = error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message
            return rejectWithValue(data)
    }
})

const serverSlice = createSlice({
    name:'server',
    initialState,
    reducers:{},
    extraReducers(builder){
        builder
        .addCase(infoServer.pending,(state) => {
            state.error = null
            state.loading = true
        })
        .addCase(infoServer.fulfilled,(state,action) => {
            state.serverInfo = action.payload
            state.loading = false
        })
        .addCase(infoServer.rejected,(state,action) => {
            state.error = action.payload
            state.loading = false
        })
    }
})

export const getServerInfo = (state) => state.server.serverInfo;
export const getLoading = (state) => state.server.loading;
export const getError = (state) => state.server.error;

export default serverSlice.reducer;