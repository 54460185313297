import { configureStore } from '@reduxjs/toolkit'
import authReducer from './reducers/authSlice'
import serverReducer from './reducers/serverSlice'
import customerReducer from './reducers/customerSlice'
import invoiceReducer from './reducers/invoiceSlice'
import paymentReducer from './reducers/paymentSlice'
import succesAnimationReducer from './reducers/succesAnimationSlice'
import customerServiceReducer from './reducers/customerServiceSlice'
import authenticationReducer from './reducers/authenticationSlice'
import customerCredentialReducer from './reducers/customerCredentialSlice'

const store = configureStore({
    reducer:{
        auth : authReducer,
        server: serverReducer,
        customer: customerReducer,
        invoice : invoiceReducer,
        payment : paymentReducer,
        succesAnimation : succesAnimationReducer,
        customerService : customerServiceReducer,
        authenticate : authenticationReducer,
        customerCredential : customerCredentialReducer
    },
    devTools: true,
})

export default store;