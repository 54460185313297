import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PrivateRoutes } from './utils';
import { Home, Login, Customer, CustomerDetail } from './pages';
import { getIsLoggedIn, getIsLogin } from './reducers/authSlice';

function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLogin);

  useEffect(() => {
    // Check local storage to persist login state
    if (localStorage.getItem('isLogin')) {
      dispatch(getIsLoggedIn());
    }
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PrivateRoutes isLoggedIn={isLoggedIn} />}>
          <Route path='/' element={<Home />} />
          <Route path='/customer' element={<Customer />} />
          <Route path='/customer/:id' element={<CustomerDetail />} />
        </Route>
        <Route path='/login' element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
