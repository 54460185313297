import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { infoServer, getServerInfo, getLoading, getError } from '../reducers/serverSlice'
import { Loading, Error } from '../components'
import { Box, Divider, Grid, Paper } from '@mui/material'
import { StatsCard } from '../components'
import { Computer, DeveloperBoard, Lan, Memory } from '@mui/icons-material'
import { blue, green, grey } from '@mui/material/colors'

const Home = () => {
    const dispatch = useDispatch();
    const server_info = useSelector(getServerInfo);
    const loading = useSelector(getLoading);
    const error = useSelector(getError)
    React.useEffect(() =>{
        dispatch(infoServer())
    },[dispatch]);
    return (
        <React.Fragment>
            {loading ? 
                <Loading />
            : error ?
                <Error severity={'error'} errorMsg={error} />
            :server_info &&
            <>
                <Paper sx={{p:{xs:1,md:1}}}>
                    <Box display={'flex'} alignItems={'center'}>
                        {/* <Typography display='block' variant='button' fontWeight='medium'>Boot Time : </Typography>
                        <Typography>{' '}</Typography>
                        <Typography variant='caption' fontWeight='regular'>{server_info.boot_time} GMT</Typography> */}
                        <h4 className='.boot_time'>BOOT TIME : </h4>
                        <span> </span>
                        <p className='boot_time_span'>{server_info.boot_time} GMT</p>
                    </Box>
                </Paper>
                <Paper sx={{p:{xs:1,md:1},marginTop:2}}>
                    <Divider>
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <h4 className='.boot_time'>CPU INFO</h4>
                        </Box>
                    </Divider>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item md={3} sm={12}>
                                    <StatsCard title={'CORE'} value={server_info.cpu_count} icon={<Computer sx={{fontSize:50, color:'#FFF'}} />} backgroundColor={blue[800]} backgroundColor1={blue[400]}/>
                                </Grid>
                                <Grid item md={3} sm={12}>
                                    <StatsCard title={'Max Frequency'} value={`${server_info.cpu_freq_max}GHz`} icon={<DeveloperBoard sx={{fontSize:50, color:'#FFF'}} />} backgroundColor={green[800]} backgroundColor1={green[400]}/>
                                </Grid>
                                <Grid item md={3} sm={12}>
                                    <StatsCard title={'Frequency'} value={`${server_info.cpu_freq}GHz`} icon={<DeveloperBoard sx={{fontSize:50, color:'#FFF'}} />} />
                                </Grid>
                                <Grid item md={3} sm={12}>
                                    <StatsCard title={'Percent'} value={`${server_info.cpu_percent}%`} icon={<DeveloperBoard sx={{fontSize:50, color:'#FFF'}} />} backgroundColor1={grey[800]} backgroundColor={grey[400]} />
                                </Grid>
                            </Grid>
                        </Box>
                </Paper>
                <Paper sx={{p:{xs:1,md:1},marginTop:2}}>
                    <Divider>
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <h4 className='.boot_time'>MEMORY INFO</h4>
                        </Box>
                    </Divider>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item md={3} sm={12}>
                                    <StatsCard title={'Total'} value={`${server_info.total_memory}GB`} icon={<Memory sx={{fontSize:50, color:'#FFF'}} />} backgroundColor={blue[800]} backgroundColor1={blue[400]}/>
                                </Grid>
                                <Grid item md={3} sm={12}>
                                    <StatsCard title={'Available'} value={`${server_info.available_memory}GB`} icon={<Memory sx={{fontSize:50, color:'#FFF'}} />} backgroundColor={green[800]} backgroundColor1={green[400]}/>
                                </Grid>
                                <Grid item md={3} sm={12}>
                                    <StatsCard title={'Used'} value={`${server_info.used_memory}GB`} icon={<Memory sx={{fontSize:50, color:'#FFF'}} />} />
                                </Grid>
                                <Grid item md={3} sm={12}>
                                    <StatsCard title={'Percent'} value={`${server_info.percent_memory}%`} icon={<Memory sx={{fontSize:50, color:'#FFF'}} />} backgroundColor1={grey[800]} backgroundColor={grey[400]} />
                                </Grid>
                            </Grid>
                        </Box>
                </Paper>
                <Paper sx={{p:{xs:1,md:1},marginTop:2}}>
                    <Divider>
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <h4 className='.boot_time'>DISK INFO</h4>
                        </Box>
                    </Divider>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item md={3} sm={12}>
                                    <StatsCard title={'Total'} value={`${server_info.total_disk}GB`} icon={<Computer sx={{fontSize:50, color:'#FFF'}} />} backgroundColor={blue[800]} backgroundColor1={blue[400]}/>
                                </Grid>
                                <Grid item md={3} sm={12}>
                                    <StatsCard title={'Available'} value={`${server_info.free_disk}GB`} icon={<Computer sx={{fontSize:50, color:'#FFF'}} />} backgroundColor={green[800]} backgroundColor1={green[400]}/>
                                </Grid>
                                <Grid item md={3} sm={12}>
                                    <StatsCard title={'Used'} value={`${server_info.used_disk}GB`} icon={<Computer sx={{fontSize:50, color:'#FFF'}} />} />
                                </Grid>
                                <Grid item md={3} sm={12}>
                                    <StatsCard title={'Percent'} value={`${server_info.percent_disk}%`} icon={<Computer sx={{fontSize:50, color:'#FFF'}} />} backgroundColor1={grey[800]} backgroundColor={grey[400]} />
                                </Grid>
                            </Grid>
                        </Box>
                </Paper>
                <Paper sx={{p:{xs:1,md:1},marginTop:2}}>
                    <Divider>
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <h4 className='.boot_time'>NETWORK INFO</h4>
                        </Box>
                    </Divider>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item md={3} sm={12}>
                                    <StatsCard title={'Send Data'} value={`${server_info.bytes_sent}GB`} icon={<Lan sx={{fontSize:50, color:'#FFF'}} />} backgroundColor={blue[800]} backgroundColor1={blue[400]}/>
                                </Grid>
                                <Grid item md={3} sm={12}>
                                    <StatsCard title={'Received Data'} value={`${server_info.bytes_recv}GB`} icon={<Lan sx={{fontSize:50, color:'#FFF'}} />} backgroundColor={green[800]} backgroundColor1={green[400]}/>
                                </Grid>
                                <Grid item md={3} sm={12}>
                                    <StatsCard title={'ERROUT'} value={server_info.errout} icon={<Lan sx={{fontSize:50, color:'#FFF'}} />} />
                                </Grid>
                                <Grid item md={3} sm={12}>
                                    <StatsCard title={'ERRIN'} value={server_info.errin} icon={<Lan sx={{fontSize:50, color:'#FFF'}} />} backgroundColor1={grey[800]} backgroundColor={grey[400]} />
                                </Grid>
                            </Grid>
                        </Box>
                </Paper>
            </>
            }
        </React.Fragment>
    )
}

export default Home