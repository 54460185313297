import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Dashboard, Logout, PeopleAlt } from '@mui/icons-material';
import { logout, getUser, getUserInfo } from '../reducers/authSlice'



const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Layout({children}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const userInfo = useSelector(getUserInfo);
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const handleLogout = () => {
    dispatch(logout());
  }
  useEffect(() => {
    dispatch(getUser());
  },[dispatch]);
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              <Link to='/' style={{textDecoration:'none',color:'white'}}>65.21.179.16</Link>
            </Typography>
            {/* <NotificationBell /> */}
            <IconButton color="inherit">
              <Typography
                  component="h6"
                  variant="body2"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1 }}
                  style={{fontWeight:600}}
              >
                  <Link to='/profile' style={{textDecoration:'none',color:'white'}} >{userInfo && userInfo.username}</Link>
              </Typography>
            </IconButton>
            <IconButton color="inherit" onClick={handleLogout}  >
              <Logout/>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          
          <List component="nav" sx={{marginTop:'55px'}}>
            <ListItemButton component={Link} to='/' selected={location.pathname === '/'}>
                <ListItemIcon>
                    <Dashboard />
                </ListItemIcon>
                <ListItemText primary='Dashboard' />
            </ListItemButton>
              <ListItemButton component={Link} to='/customer' selected={location.pathname === '/customer'}>
                  <ListItemIcon>
                      <PeopleAlt/>
                  </ListItemIcon>
                  <ListItemText primary='Customer' />
              </ListItemButton>
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {children}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}