import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInstance } from '../utils'

const initialState = {
    invoice :[],
    page:1,
    pages:1,
    isCreate : false,
    createLoading : false,
    createError : null,
    isLoading: false,
    error:null,
    
}

export const listInvoice = createAsyncThunk('invoice/list',async ({id,page}, {rejectWithValue}) =>{
    try{
        const response = await axiosInstance.get(`customer/detail/${id}/invoice/?page=${page}`)
        return response.data;
    }catch (error) {
        const data  = error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message
            return rejectWithValue(data)
    }
})

export const createInvoice = createAsyncThunk('invoice/create',async (credentials, {rejectWithValue}) =>{
    try{
        const response = await axiosInstance.post(`customer/invoice/create/`,credentials)
        return response.data;
    }catch (error) {
        const data  = error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message
            return rejectWithValue(data)
    }
})

const invoiceSlice = createSlice({
    name:'invoice',
    initialState,
    reducers:{
        resetInvoice : (state)=>{
            state.createError = null
            state.createLoading = false
            state.error = null
            state.invoice = []
            state.isCreate = false
            state.isLoading = false
            state.page = 1
            state.pages = 1
        },
        setCurrentPage:(state,action)=>{
            state.page = action.payload
        }
    },
    extraReducers(builder){
        builder
        .addCase(listInvoice.pending,(state) => {
            state.error = null
            state.isLoading = true
        })
        .addCase(listInvoice.fulfilled,(state,action)=>{
            const { invoices,page,pages } = action.payload
            state.invoice = invoices
            state.page = page
            state.pages = pages
            state.isLoading = false
        })
        .addCase(listInvoice.rejected,(state,action)=>{
            state.error = action.payload
            state.isLoading = false
        })
        .addCase(createInvoice.pending,(state) =>{
            state.createError = null
            state.createLoading = true
        })
        .addCase(createInvoice.fulfilled,(state)=>{
            state.isCreate = true
            state.createLoading = false
        })
        .addCase(createInvoice.rejected,(state,action) =>{
            state.createError = action.payload
            state.createLoading = false
        })
    }
})

export const { resetInvoice, setCurrentPage } = invoiceSlice.actions;

export const getInvoices = (state) => state.invoice.invoice;
export const getLoading = (state) => state.invoice.isLoading;
export const getError = (state) => state.invoice.error;
export const getIsCreated = (state) => state.invoice.isCreate;
export const getCreateLoading = (state) => state.invoice.createLoading;
export const getCreateError = (state) => state.invoice.createError;
export const getPage = (state) => state.invoice.page;
export const getPages = (state) => state.invoice.pages;

export default invoiceSlice.reducer;
