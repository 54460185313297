import React,{ useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Divider, Paper, TableHead, TableCell, TableContainer, Table, TableBody, Grid, FormControl, InputLabel, OutlinedInput, InputAdornment, TextField, MenuItem, Pagination } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
    listInvoice,
    createInvoice,
    resetInvoice,
    getInvoices,
    getLoading,
    getError,
    getPage,
    getPages,
    getIsCreated,
    getCreateLoading,
    getCreateError,
    setCurrentPage
} from '../reducers/invoiceSlice'
import { getIsCreated as getIsCreatedPayment } from '../reducers/paymentSlice'
import { setActive } from '../reducers/succesAnimationSlice'
import { Loading, Error, Warning, InvoiceList, Popup, PopLoading } from '../components'
import { CurrencyRupee } from '@mui/icons-material'
import { listCustomerService, getCustomerService } from '../reducers/customerServiceSlice'

const StyleTableHead = styled(TableHead)(({theme})=>({
    fontWeight: '600',
    // color: theme.palette.primary,
    backgroundColor: '#adb5bd',
}))

const StyleTableCell = styled(TableCell)(({theme})=>({
    // color: theme.palette.primary.main,
    textAlign:'center'
}))

const CustomerPayment = ({id}) => {
    const dispatch = useDispatch();
    const invoices = useSelector(getInvoices);
    const page = useSelector(getPage);
    const pages = useSelector(getPages);
    const IsLoading = useSelector(getLoading);
    const customerService = useSelector(getCustomerService);
    const error = useSelector(getError);
    const isCreated = useSelector(getIsCreated);
    const createLoading = useSelector(getCreateLoading);
    const createError = useSelector(getCreateError);
    const isCreatedPayment = useSelector(getIsCreatedPayment);
    const [openInvoice,setOpenInvoice] = useState(false);
    const [amount,setAmount] = useState();
    const [serviceTitle,setServiceTitle] = useState();
    const canSubmit = Boolean(amount) && Boolean(serviceTitle) && !createLoading;
    const handleChange = (e,p) => {
        dispatch(setCurrentPage(p));
        dispatch(listInvoice({page:p,id}));
    }
    const handleAmount = (e) => {
        if(Number(e.target.value)){
            setAmount(Number(e.target.value))
        }else{
            setAmount('');
        }
    }
    const handleSubmit = () => {
        dispatch(createInvoice({id,amount,serviceTitle}));
    }
    useEffect(()=>{
        dispatch(listInvoice({id,page}));
        dispatch(listCustomerService(id));
        if(isCreatedPayment){
            dispatch(resetInvoice());
        }
        if(isCreated){
            setOpenInvoice(false);
            dispatch(setActive(true));
            dispatch(resetInvoice());
        }
    },[dispatch,id,isCreatedPayment,isCreated]);
    return (
        <TableContainer component={Paper} sx={{ p: {xs: 2, md: 3}}}>
            <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                <Button variant='contained' onClick={()=>setOpenInvoice(true)} >Add Invoice</Button>
            </Box>
            <Divider>
                <h4 className='.boot_time'>Customer INFO</h4>
            </Divider>
            {IsLoading  ?
                <Loading/>
            :error ?
                <Error severity={'error'} errorMsg={error} />
            // :isCreatedPayment ?
            //     <SuccessAnimation setIsVisible={setIsSuccessShow} />
            :invoices && invoices.length === 0 ?
                <Warning message={'No invoice found!!!'} />
            :invoices&&
            <>
            <Table size='small'>
                <StyleTableHead>
                    <StyleTableCell>Title</StyleTableCell>
                    <StyleTableCell>Amount</StyleTableCell>
                    <StyleTableCell>Date</StyleTableCell>
                    <StyleTableCell>User</StyleTableCell>
                    <StyleTableCell>Status</StyleTableCell>
                    <StyleTableCell>Action</StyleTableCell>
                </StyleTableHead>
                <TableBody>
                    {invoices.map(item =>(
                        <InvoiceList item={item} />
                    ))}
                </TableBody>
            </Table>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Pagination onChange={handleChange} page={page} count={pages} />
            </Box>
            </>
            }
            <Popup
                title={'Create Invoice'}
                openPopup={openInvoice}
                setOpenPopup={setOpenInvoice}
            >
                <Grid container spacing={2}>
                    <Grid item md={12} xl={12} sm={12}>
                        <TextField label='Service Title' size='small' fullWidth select value={serviceTitle} onChange={(e)=>setServiceTitle(e.target.value)}>
                            {customerService && customerService.map((item) => {
                                return(
                                    <MenuItem key={item.id} value={item.title}>{item.title}</MenuItem>
                                )
                            })}
                        </TextField>
                    </Grid>
                    <Grid item md={12} xl={12} sm={12}>
                        <FormControl fullWidth size='small'>
                            <InputLabel htmlFor='amount'>Amount</InputLabel>
                            <OutlinedInput 
                                id='amount'
                                startAdornment={<InputAdornment position='start'><CurrencyRupee/></InputAdornment>}
                                label='Amount'
                                required
                                value={amount}
                                onChange={handleAmount}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                {createError &&
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} mt={1}>
                        <Error severity={'error'} errorMsg={createError} />
                    </Box>
                }
                <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} mt={2}>
                    <Button variant='contained' onClick={handleSubmit} disabled={!canSubmit} >{createLoading ? <PopLoading/>:'Submit'}</Button>
                </Box>
            </Popup>
        </TableContainer>
    )
}

export default CustomerPayment