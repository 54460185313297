import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
    
    // superuser
    headers: {
        'Content-type': 'application/json',
        // 'Authorization': `Token 45918d1bddfafe4987656cecc44437edb56bf14b`,
    },

    // user
    // headers: {
    //     'Content-type': 'application/json',
    //     'Authorization': `Token 26132b809ff6d030de9f6f8757c30d23cba98d5a`,
    // },
});

export default axiosInstance;