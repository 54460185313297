import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInstance } from '../utils'

const initialState = {
    customerCredential : [],
    loading : false,
    error : null,
    createLoading : false,
    createError : null,
    isCreated : false
}

export const createCustomerCredential = createAsyncThunk('customer/credential/create', async (credentials, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('customer/credential/create/', credentials);
      return response.data;
    } catch (error) {
      const data = error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message;
      return rejectWithValue(data);
    }
});

export const listCustomerCredential = createAsyncThunk('customer/credential/list', async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`customer/credential/list/${id}/`);
      return response.data;
    } catch (error) {
      const data = error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message;
      return rejectWithValue(data);
    }
});

const customerCredentialSlice = createSlice({
    name:'customerCredential',
    initialState,
    reducers:{
        resetCustomerCredential:(state) => {
            state.customerCredential = []
            state.loading = false
            state.error = null
            state.createLoading = false
            state.createError = null
            state.isCreated = false
        }
    },
    extraReducers(builder){
        builder
        .addCase(listCustomerCredential.pending,(state) => {
            state.error = null
            state.loading = true
        })
        .addCase(listCustomerCredential.fulfilled,(state,action) => {
            state.customerCredential = action.payload
            state.loading = false
        })
        .addCase(listCustomerCredential.rejected,(state,action) => {
            state.error = action.payload
            state.loading = false
        })
        .addCase(createCustomerCredential.pending,(state) => {
            state.createError = null
            state.createLoading = true
        })
        .addCase(createCustomerCredential.fulfilled,(state,action) => {
            state.isCreated = true
            state.createLoading = false
        })
        .addCase(createCustomerCredential.rejected,(state,action) => {
            state.createError = action.payload
            state.createLoading = false
        })
    }
})

export const { resetCustomerCredential } = customerCredentialSlice.actions;

export const getCustomerCredential = (state) => state.customerCredential.customerCredential;
export const getLoading = (state) => state.customerCredential.loading;
export const getError = (state) => state.customerCredential.error;
export const getIsCreated = (state) => state.customerCredential.isCreated;
export const getCreateLoading = (state) => state.customerCredential.createLoading;
export const getCreateError = (state) => state.customerCredential.createError;

export default customerCredentialSlice.reducer