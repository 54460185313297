import React from 'react'
import { styled } from '@mui/material/styles'
import { Dialog, Typography, DialogTitle, Button, DialogContent } from '@mui/material'
import { Close } from '@mui/icons-material'

const StyleDialogWrapper = styled(Dialog)(({theme})=>({
    padding: theme.spacing(2),
    position: 'absolute',
    top: theme.spacing(5)
}))

const StyleDialogTitle = styled(DialogTitle)(({theme})=>({
    paddingRight: '0px'
}))

const Popup = ({title,children,openPopup,setOpenPopup}) => {
    return (
        <StyleDialogWrapper open={openPopup} maxWidth="md">
            <StyleDialogTitle>
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    <Button variant='outlined' color='error'  onClick={() => setOpenPopup(false)}>
                        <Close color='error'/>
                    </Button>
                </div>
            </StyleDialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
        </StyleDialogWrapper>
    )
}

export default Popup