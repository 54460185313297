import { Box } from '@mui/material'
import React from 'react'
import { tailChase } from 'ldrs'



const Loading = () => {
    tailChase.register()
    return (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{ paddingTop:'100px' }}>
            <l-tail-chase
                size="55"
                speed="1.75" 
                color="#f83245" 
            ></l-tail-chase>
        </Box>
        
    )
}

export default Loading